import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Routes, Route} from "react-router-dom";
import RequireAuth from "./auth/RequireAuth";
import CheckUserLogged from "./auth/CheckUserLogged";
import GtmDataLayer from "./components/GtmDataLayer";

// context providers
import {AuthContextProvider} from "./contexts/AuthContext";
import {MarginCalculatorContextProvider} from "./contexts/MarginCalculatorContext";
import {ShippingContextProvider} from "./contexts/ShippingContext";
import {DiscountTableContextProvider} from "./contexts/DiscountTableContext";

// pages
import Login from "./pages/Login";
import Maintenance from "./pages/Maintenance";
import MicrosoftLogin from "./pages/MicrosoftLogin";
import Dashboard from "./pages/Dashboard";
import UsersManagement from "./pages/UsersManagement";
import MarginCalculation from "./pages/MarginCalculation";
import PageNotFound from "./pages/PageNotFound";
import ResetPassword from "./pages/ResetPassword";
import SetNewPassword from "./pages/SetNewPassword";
import CartPage from "./pages/CartPage";
import OrderSent from "./pages/OrderSent";
import Carts from "./components/carts/Carts";
import Offers from "./pages/Offers";
import Invoices from "./pages/Invoices";
import Shippings from "./pages/Shippings";
import OfferPage from "./pages/OfferPage";
import OfferCreated from "./pages/OfferCreated";
import Assistance from "./pages/Assistance";
import ReservedConditions from "./pages/ReservedConditions";
import ComplaintSent from "./pages/ComplaintSent";
import OrderPage from "./pages/OrderPage";
import Profile from "./pages/Profile";
import ProfileSent from "./pages/ProfileSent";

// partials
import Header from "./partials/Header";

import {Helmet, HelmetProvider} from "react-helmet-async";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import ScrollToTop from "./components/ScrollToTop";
import {checkBuildVersion} from "./utils/checkBuildVersion";
import {checkSession} from "./utils/checkSession";
import {NewVersionModal} from "./components/NewVersionModal";
import EserviceDown from "./pages/EserviceDown";

const queryClient = new QueryClient();

function App() {
    const {t} = useTranslation();

    const removeNewLineFromMultipleRowsCopy = (e: any) => {
        const selection: any = document.getSelection();
        e.clipboardData.setData("text/plain", selection.toString().replace(/(\t|\r|\n)/gm, " "));
        e.preventDefault();
    };

    let appTitle = "E-Service | Rothoblaas";
    if (process.env.REACT_APP_ENV !== "production") {
        appTitle = `E-Service ${t("test environment")} | Rothoblaas`;
    }

    const [modalVersionStatus, setModalVersionStatus] = useState<boolean>(false);

    useEffect(() => {
        checkBuildVersion(setModalVersionStatus);
        checkSession();
    }, []);

    return (
        <div className="flex flex-col h-screen" onCopy={(e) => {
            removeNewLineFromMultipleRowsCopy(e);
        }}>

            <HelmetProvider>
                <Helmet>
                    <title>{appTitle}</title>
                </Helmet>
            </HelmetProvider>

            <QueryClientProvider client={queryClient}>

                <AuthContextProvider>

                    <GtmDataLayer/>

                    <MarginCalculatorContextProvider>

                        <ShippingContextProvider>

                            <DiscountTableContextProvider>

                                <ScrollToTop/>

                                <Header/>

                                <NewVersionModal modalVersionStatus={modalVersionStatus}/>

                                <Routes>
                                    {/* maintenance  */}
                                    <Route path='maintenance' element={<Maintenance/>}/>
                                    <Route path='down' element={<EserviceDown/>}/>

                                    {/* login routes  */}
                                    <Route element={<CheckUserLogged/>}>
                                        <Route path="/" element={<Login/>}/>
                                        <Route path="/microsoft-login" element={<MicrosoftLogin/>}/>

                                        {/* reset password routes  */}
                                        <Route path='/reset-password/'>
                                            <Route path="" element={<ResetPassword/>}/>
                                            <Route path="new-password" element={<SetNewPassword/>}/>
                                        </Route>
                                    </Route>

                                    {/* protected routes  */}
                                    <Route element={<RequireAuth allowedRoles={["can_manage_employees", "can_manage_clients"]}/>}>
                                        <Route path="/users-management" element={<UsersManagement/>}/>
                                    </Route>
                                    <Route element={<RequireAuth allowedRoles={["can_see_margin_calculator"]}/>}>
                                        <Route path="/margin-calculator" element={<MarginCalculation/>}/>
                                    </Route>

                                    <Route element={<RequireAuth/>}>
                                        <Route path="dashboard" element={<Dashboard/>}/>
                                        <Route path="lists" element={<Carts/>}/>
                                        <Route path='carts/*' element={<CartPage/>}/>

                                        <Route element={<RequireAuth allowedRoles={['can_see_orders_list']}/>}>
                                            <Route path='orders/*' element={<OrderPage/>}/>
                                        </Route>

                                        <Route path="order-sent/:id" element={<OrderSent/>}/>

                                        <Route element={<RequireAuth allowedRoles={['can_see_offers_list']}/>}>
                                            <Route path='offers' element={<Offers/>}/>
                                            <Route path='offers/*' element={<OfferPage/>}/>
                                            <Route path="offers/:id/created" element={<OfferCreated/>}/>
                                        </Route>

                                        <Route element={<RequireAuth allowedRoles={['can_see_invoices_list']}/>}>
                                            <Route path='invoices' element={<Invoices/>}/>
                                        </Route>

                                        <Route element={<RequireAuth allowedRoles={['can_see_shipments_list']}/>}>
                                            <Route path='shippings' element={<Shippings/>}/>
                                        </Route>

                                        <Route element={<RequireAuth allowedRoles={["can_see_complaints_list"]}/>}>
                                            <Route path='assistance/*' element={<Assistance/>}/>
                                        </Route>

                                        <Route path='complaint-sent/:id' element={<ComplaintSent/>}/>

                                        <Route element={<RequireAuth allowedRoles={["can_see_discounted_pricelist", "can_manage_discount_table", "can_see_gross_pricelist", "can_see_marketing_pricelist"]}/>}>
                                            <Route path='reserved-conditions/*' element={<ReservedConditions/>}/>
                                        </Route>

                                        <Route element={<RequireAuth allowedRoles={["can_see_profile_data", "can_see_logistic_data", "can_see_contacts_data", "can_see_administrative_data", "can_see_financial_data"]}/>}>
                                            <Route path='profile/*' element={<Profile/>}/>
                                        </Route>

                                        <Route path='profile-sent' element={<ProfileSent/>}/>
                                    </Route>

                                    {/* page not found route  */}
                                    <Route path='*' element={<PageNotFound/>}/>
                                </Routes>

                                <ReactQueryDevtools initialIsOpen={false}/>

                            </DiscountTableContextProvider>

                        </ShippingContextProvider>

                    </MarginCalculatorContextProvider>

                </AuthContextProvider>

            </QueryClientProvider>

        </div>
    );
}

export default App;
