import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/Button";
import { Title } from "../components/Title";
import MarginCalculationTable from "../components/margin_calculator/MarginCalculationTable";
import client from "../api/client";
import { downloadResource } from "../constants/DownloadResource";
import { useAuthContext } from "../hooks/use-context/useAuthContext";
import { useMarginCalculatorContext } from "../hooks/use-context/useMarginCalculatorContext";
import { Item } from "../interfaces/Item";
import useMarginCalculationMethods from "../hooks/useMarginCalculationMethods";
import { FiSearch } from "react-icons/fi";
import { MarginCalculationImport } from "../components/margin_calculator/MarginCalculationImport";
import useGetCustomers from "../api/customers/useGetCustomers";
import { Slide, ToastContainer, toast } from "react-toastify";
import { ItemSelect } from "../components/commom/ItemSelect";
import { CustomerSelect } from "../components/commom/CustomerSelect";
import { Section } from "../components/Section";
import { Loader } from "../components/Loader";

const MarginCalculation: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // context
    const { can } = useAuthContext();

    // force check on margin calculator
    if (!can(["can_see_margin_calculator"])) {
        navigate("/dashboard");
    }

    const { toolMarginiItems, setToolMarginiItems, selectedCustomer, setSelectedCustomer } = useMarginCalculatorContext();

    // get margin calculator methods
    const { skipPageReset, addItem, deleteRow, updateTableData, semiramisRound, calculateNetPrice, handleNumberDecimals, getCurrencyDecimals } = useMarginCalculationMethods();

    const { loadCustomersOptions } = useGetCustomers();

    // state
    const [selectedItem, setSelectedItem] = useState<Item | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [isQtyValid, setIsQtyValid] = useState(true);
    // const [checkXlsx, setCheckXlsx] = useState(false);
    const [isTableValid, setIsTableValid] = useState<any>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);


    // refs
    const searchSelect: any = useRef();
    const customerSelect: any = useRef();
    const qtyInput: any = useRef();

    // set hq hidden columns if customer org = IT001
    let hiddenColums: string[];
    if (selectedCustomer?.organization_id === "IT001") {
        hiddenColums = ["materialpriceEUR", "Margin", "Total_margin"];
    } else {
        hiddenColums = [];
    }

    // handle customer search SELECT change
    const handleCustomerSelect = (e: any) => {
        setSelectedCustomer(e);
        setToolMarginiItems([]);

        if (e) {
            searchSelect.current.focus();
        } else if (!e) {
            setSelectedCustomer(null);
            searchSelect.current.clearValue();
        }
    };

    // handle item search SELECT change
    const handleItemSearchChange = (e: any) => {
        setSelectedItem(e?.value);
        setIsQtyValid(true);
        qtyInput.current.value = 1;

        if (e) {
            qtyInput.current.min = Math.floor(e.value.minsellable);
            qtyInput.current.step = Math.floor(e.value.minsellable);
            qtyInput.current.value = Math.floor(e.value.minsellable);
        }
    };

    //check if qty is valid
    const checkQty = (e: any) => {
        if (selectedItem)
            if (e.target.value % selectedItem?.minsellable !== 0 || qtyInput.current.value === "") {
                setIsQtyValid(false);
            } else {
                setIsQtyValid(true);
            }
    };

    // handle submit form
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (toolMarginiItems.length >= 100) throw new Error(t("Limite massimo di {{max_items}} articoli superato", { max_items: 100 }));

            setDisabled(true);

            const params = {
                item_code: selectedItem?.code,
                item_qty: qtyInput.current.value,
                customer_code: selectedCustomer.code,
            };

            if (selectedItem) {
                const res = await client.get("items/margin-calculation", { params: params });

                addItem({
                    item: res.data.item,
                    itemSC1: res.data.itemSC1,
                    itemSC2: res.data.itemSC2,
                    selectedItem: selectedItem
                }, qtyInput.current.value);
            }
            // clear input fields and selected item to avoid adding saved data when clicking more than once
            setSelectedItem(null);
            setDisabled(false);
            qtyInput.current.value = 1;
            searchSelect.current.clearValue();
            searchSelect.current.focus();
        } catch (err: any) {
            toast(err.message);
        }
    };

    // GET ITEMS
    const getItems = useCallback(async (value: string) => {
        const params = {
            code: value,
            customer_code: selectedCustomer.code,
        };
        const res = await client.get("items/margin-calculator-itemslist", { params: params });

        // console.log(res);

        return res.data.items.map((item: any) => {
            let pieces = null;
            if (parseInt(item.piecesperpackage, 10) > 1) {
                pieces = <>({parseInt(item.piecesperpackage, 10)} {item.piecesperpackageuom})</>;
            }
            return {
                label: <span>{item.code} - {item.description} {pieces}</span>,
                value: item
            };
        });

    }, [selectedCustomer]);

    const loadItemsOptions = useMemo(() => {
        return debounce((value: any, callback: any) => {
            getItems(value).then((options) => callback(options));
        }, 1000);
    }, [getItems]);

    // DOWNLOAD FILE
    const downloadFile = async (url: string) => {

        setIsFetching(true);

        const params = {
            table_data: JSON.stringify(toolMarginiItems),
            ts_fullname: selectedCustomer.ts_fullname,
            customer_name: selectedCustomer.name,
            customer_org: selectedCustomer.organization_id,
            customer_country: selectedCustomer.country_id,
            customer_code: selectedCustomer.code,
            customer_currency: selectedCustomer.currency,
        };

        const res = await client.post(url, params, {
            headers: {
                responseType: "blob"
            }
        });

        // mode download
        if (res.data && res.data.content && res.data.name && res.data.type) {
            const filetype = res.data.type;
            const content = res.data.content;
            const filename = res.data.name;

            downloadResource(content, filename, filetype);
        }

        setIsFetching(false);
    };

    useEffect(() => {
        searchSelect.current.focus();
    }, [selectedCustomer]);

    // set focus on customer input when page is loaded
    useEffect(() => {
        customerSelect.current.focus();
        qtyInput.current.value = 1;
    }, []);

    // set focus on qty when an item is selected
    useEffect(() => {
        if (selectedItem) {
            qtyInput.current.select();
            qtyInput.current.focus();
        }
    }, [selectedItem]);

    // check if all talble inputs are valid
    useEffect(() => {
        for (let i = 0; i < toolMarginiItems.length; i++) {
            if (toolMarginiItems[i].qty % toolMarginiItems[i].minsellable !== 0 || toolMarginiItems[i].qty <= 0) {
                setIsTableValid((prevState: any) => prevState.includes(i) ? prevState : [...prevState, i]);
            } else {
                setIsTableValid((prevState: any) => prevState.filter((index: any) => {
                    return index !== i;
                }));
            }
        }
    }, [toolMarginiItems]);

    return <>
        {
            isFetching && <Loader />
        }
        <div className="py-10 px-8 2xl:px-28">
            <Title>{t("Margin calculator")}</Title>

            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />

            <form onSubmit={(e) => handleSubmit(e)}>
                <fieldset className="w-full md:w-1/2">
                    <legend className="sr-only">{t("Cliente")}</legend>
                    {/* CUSTOMER SELECT */}
                    <CustomerSelect
                        label="Seleziona cliente"
                        ref={customerSelect}
                        loadCustomersOptions={loadCustomersOptions}
                        handleChange={handleCustomerSelect}
                        selectedCustomer={selectedCustomer}
                        setSelectedCustomer={setSelectedCustomer}
                    />
                </fieldset>

                {/* ADD PRODUCT */}
                <fieldset>
                    <Section title={"Aggiungi prodotto"} className="mb-10">
                        <legend className="sr-only">{t("Aggiungi prodotto")}</legend>
                        <div className="space-y-5">
                            {/* ITEM SELECT */}
                            <div className="flex items-center gap-6">
                                <div className="md:w-2/3">
                                    <label htmlFor="">{t("Codice articolo o nome prodotto")}</label>
                                    <ItemSelect
                                        ref={searchSelect}
                                        loadItemsOptions={loadItemsOptions}
                                        handleChange={handleItemSearchChange}
                                        disabled={!selectedCustomer}
                                    />
                                </div>
                                {/* QTY */}
                                <div className='flex items-center gap-x-6 w-1/3'>
                                    <div className='w-1/3'>
                                        <label htmlFor="">{t("Quantità")}</label>
                                        <input
                                            onChange={(e) => {
                                                checkQty(e);
                                            }}
                                            ref={qtyInput}
                                            className={`${!selectedItem ? "cursor-not-allowed text-disabled" : ""} ${!isQtyValid ? "border-rose-500 outline-none rounded" : ""} `}
                                            type="number"
                                            disabled={!selectedItem}
                                            required
                                        />
                                    </div>
                                    <div className="self-end w-2/3">
                                        <Button variant={Button.variant.primary} size={Button.size.full} isDisabled={disabled || !selectedItem}>
                                            {t("Aggiungi")}
                                        </Button>
                                    </div>
                                </div>

                                {/* IMPORT */}
                            </div>

                            <div>
                                <MarginCalculationImport />
                            </div>
                        </div>
                    </Section>
                </fieldset>
            </form>

            {/* MARGIN TABLE  */}
            {
                toolMarginiItems.length > 0 && selectedCustomer
                    ? <MarginCalculationTable
                        items={toolMarginiItems}
                        hiddenColums={hiddenColums}
                        updateTableData={updateTableData}
                        deleteRow={deleteRow}
                        skipPageReset={skipPageReset}
                        semiramisRound={semiramisRound}
                        calculateNetPrice={calculateNetPrice}
                        getCurrencyDecimals={getCurrencyDecimals}
                        handleNumberDecimals={handleNumberDecimals}
                        selectedCustomer={selectedCustomer}
                        isTableValid={isTableValid}
                    />
                    :
                    <div className="h-96 flex flex-col items-center justify-center space-y-6">
                        <span className="text-9xl text-gray-200"><FiSearch /></span>
                        <p className="text-2xl text-gray-600">{t("inserisci un prodotto per calcolare i margini.")}</p>
                    </div>
            }

            {/* BUTTONS */}
            {
                toolMarginiItems.length > 0 &&
                <>
                    <div className="flex justify-between">
                        <div>
                            <Button
                                icon={Button.icon.delete}
                                variant={Button.variant.text}
                                onClick={() => {
                                    setToolMarginiItems([]);
                                }}
                            >
                                {t("Svuota lista prodotti")}
                            </Button>
                        </div>
                        <div className="space-x-4">
                            <Button
                                icon={Button.icon.download}
                                variant={Button.variant.primary}
                                onClick={(e) => {
                                    e.preventDefault();
                                    downloadFile("/download/margin-calculation-xlsx");
                                }}
                                isDisabled={isTableValid.length > 0}
                            >
                                {t("Scarica XLS")}
                            </Button>

                            <Button
                                icon={Button.icon.download}
                                variant={Button.variant.primary}
                                onClick={(e) => {
                                    e.preventDefault();
                                    downloadFile("/download/margin-calculation-pdf");
                                }}
                                isDisabled={isTableValid.length > 0}
                            >
                                {t("Scarica PDF")}
                            </Button>
                        </div>
                    </div>
                </>
            }
        </div>
    </>
};

export default MarginCalculation;
