import { useTranslation } from "react-i18next";
import { useState } from "react";
import { SelectAddressModal } from "../../../commom/SelectAddressModal";
import { FaPen } from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";
import { Section } from "../../../Section";

type Props = {
    data: any,
    setOpenNewAddress: any
};

export const DeliveryAddress = ({ data, setOpenNewAddress }: Props) => {
    const { t } = useTranslation();

    const { deliveryCustomer, address } = data?.data || {};

    const selectedAddress = address?.new_address ? address : deliveryCustomer;


    // state
    const [openSelectAddress, setOpenSelectAddress] = useState(false);

    return (
        <Section title={"Indirizzo di consegna"}>
            <div className='border'>
                <div className="p-6 space-y-1">
                    {
                        selectedAddress ?
                            <>
                                <div className="font-light">{selectedAddress?.name}</div>
                                <div className="font-light">{selectedAddress?.street}</div>
                                <div className="font-light">{selectedAddress?.city}, {selectedAddress?.region_id} {selectedAddress?.postalcode} {selectedAddress?.country_id}</div>
                                {
                                    selectedAddress?.phone &&
                                    <div className="flex gap-x-1">
                                        <div>{t("Numero di telefono")}:</div>
                                        <div className="font-light">{selectedAddress?.phone}</div>
                                    </div>
                                }
                            </>
                            :
                            <p>{t("Indirizzo di spedizione non impostato, si prega di selezionarne uno dall’elenco.")}</p>
                    }


                    <div className="pt-6">
                        <hr />
                        <div className="flex gap-6">
                            <div className="flex items-center gap-x-2 cursor-pointer hover:underline pt-4 w-fit" onClick={() => setOpenSelectAddress(true)}>
                                <FaPen />
                                <div className="font-normal font-sans text-base">
                                    {t("Scegli indirizzo")}
                                </div>
                            </div>
                            <div className="flex items-center gap-x-2 cursor-pointer hover:underline pt-4 w-fit" onClick={() => setOpenNewAddress(true)}>
                                <AiOutlinePlus />
                                <div className="font-normal font-sans text-base">
                                    {selectedAddress?.new_address ? t("Modifica indirizzo") : t("Nuovo indirizzo")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* MODALS */}
            {openSelectAddress && <SelectAddressModal data={data} open={openSelectAddress} onClose={setOpenSelectAddress} />}
        </Section>
    );
};
